import {
  TypedUseSelectorHook,
  useDispatch as _useDispatch,
  useSelector as _useSelector,
} from 'react-redux';
import type { AppDispatch, RootState } from './store';
import {
  buildActiveAccount,
  buildChecklist,
  buildChecklistItem,
  buildChecklistsFromUnisonProjectID,
  buildConversation,
  buildConversationMessage,
  buildConversationMessageAttachments,
  buildConversationMessageFileCollections,
  buildConversationMessageFiles,
  buildConversationMessages,
  buildConversationsFromPracticeID,
  buildConversationsFromUnisonProjectID,
  buildConversationUsers,
  buildCurrentUser,
  buildDateFormatPreference,
  buildFile,
  buildFileCollection,
  buildFileCollectionsByStepID,
  buildFileCollectionsFromIDArray,
  buildFileCollectionsFromParentID,
  buildFilesByStepID,
  buildFilesFromFileCollectionID,
  buildFilesFromIDArray,
  buildLead,
  buildLeadUserProperties,
  buildLogoPreference,
  buildPracticePreferences,
  buildPracticeProperties,
  buildPracticeUnisonProjects,
  buildSequence,
  buildSequencesFromFileCollectionID,
  buildStep,
  buildStepsFromSequenceID,
  buildTreeByStepID,
  buildUnisonProject,
  buildWeekStartsOnPreference,
} from './utils/buildState';
import isEqual from 'lodash/isEqual';

export const useDispatch: () => AppDispatch = _useDispatch;
export const useSelector: TypedUseSelectorHook<RootState> = _useSelector;

function useBuilder(
  buildMethod: (state: RootState, identifier?: any) => any,
  identifier?: any
) {
  if (identifier !== undefined) {
    return useSelector((state) => buildMethod(state, identifier), isEqual);
  } else {
    return useSelector((state) => buildMethod(state), isEqual);
  }
}

export function useActiveAccount(): ReturnType<typeof buildActiveAccount> {
  return useBuilder(buildActiveAccount);
}

export function useCurrentUser(): ReturnType<typeof buildCurrentUser> {
  return useBuilder(buildCurrentUser);
}

export function useDateFormatPreference(): ReturnType<
  typeof buildDateFormatPreference
> {
  return useBuilder(buildDateFormatPreference);
}

export function useWeekStartsOnPreference(): ReturnType<
  typeof buildWeekStartsOnPreference
> {
  return useBuilder(buildWeekStartsOnPreference);
}

export function usePracticeProperties(): ReturnType<
  typeof buildPracticeProperties
> {
  return useBuilder(buildPracticeProperties);
}

export function usePracticePreferences(
  practiceID?: number
): ReturnType<typeof buildPracticePreferences> {
  return useBuilder(buildPracticePreferences, practiceID);
}

export function usePracticeLogo(
  practiceID?: number
): ReturnType<typeof buildLogoPreference> {
  return useBuilder(buildLogoPreference, practiceID);
}

export function useLead(leadID?: number): ReturnType<typeof buildLead> {
  return useBuilder(buildLead, leadID);
}

export function useLeadUserProperties(
  leadID?: number
): ReturnType<typeof buildLeadUserProperties> {
  return useBuilder(buildLeadUserProperties, leadID);
}

export function useUnisonProject(
  projectID?: number | null
): ReturnType<typeof buildUnisonProject> {
  return useBuilder(buildUnisonProject, projectID);
}

export function usePracticeUnisonProjects(
  practiceID?: number
): ReturnType<typeof buildPracticeUnisonProjects> {
  return useBuilder(buildPracticeUnisonProjects, practiceID);
}

export function useChecklistsFromUnisonProjectID(
  unisonProjectID: number
): ReturnType<typeof buildChecklistsFromUnisonProjectID> {
  return useBuilder(buildChecklistsFromUnisonProjectID, unisonProjectID);
}

export function useChecklist(
  checklistID: number
): ReturnType<typeof buildChecklist> {
  return useBuilder(buildChecklist, checklistID);
}

export function useChecklistItem(
  itemID?: number
): ReturnType<typeof buildChecklistItem> {
  return useBuilder(buildChecklistItem, itemID);
}

export function useFileCollection(
  fileCollectionID?: number | null
): ReturnType<typeof buildFileCollection> {
  return useBuilder(buildFileCollection, fileCollectionID);
}

export function useFileCollectionsFromIDArray(
  idArray: number[]
): ReturnType<typeof buildFileCollectionsFromIDArray> {
  return useBuilder(buildFileCollectionsFromIDArray, idArray);
}

export function useFileCollectionsFromParentID(
  parentID?: number | null
): ReturnType<typeof buildFileCollectionsFromParentID> {
  return useBuilder(buildFileCollectionsFromParentID, parentID);
}

export function useFilesFromFileCollectionID(
  fileCollectionID?: number | null
): ReturnType<typeof buildFilesFromFileCollectionID> {
  return useBuilder(buildFilesFromFileCollectionID, fileCollectionID);
}

export function useFile(fileID: number): ReturnType<typeof buildFile> {
  return useBuilder(buildFile, fileID);
}

export function useFilesFromIDArray(
  idArray: number[]
): ReturnType<typeof buildFilesFromIDArray> {
  return useBuilder(buildFilesFromIDArray, idArray);
}

export function useConversation(
  conversationID?: number | null
): ReturnType<typeof buildConversation> {
  return useBuilder(buildConversation, conversationID);
}

export function useConversationMessages(
  conversationID?: number | null
): ReturnType<typeof buildConversationMessages> {
  return useBuilder(buildConversationMessages, conversationID);
}

export function useConversationMessage(
  messageID: number
): ReturnType<typeof buildConversationMessage> {
  return useBuilder(buildConversationMessage, messageID);
}

export function useConversationMessageFiles(
  messageID: number
): ReturnType<typeof buildConversationMessageFiles> {
  return useBuilder(buildConversationMessageFiles, messageID);
}

export function useConversationMessageFileCollections(
  messageID: number
): ReturnType<typeof buildConversationMessageFileCollections> {
  return useBuilder(buildConversationMessageFileCollections, messageID);
}

export function useConversationMessageAttachments(
  messageID: number
): ReturnType<typeof buildConversationMessageAttachments> {
  return useBuilder(buildConversationMessageAttachments, messageID);
}

export function useConversationUsers(
  conversationID?: number | null
): ReturnType<typeof buildConversationUsers> {
  return useBuilder(buildConversationUsers, conversationID);
}

export function useConversationsFromUnisonProjectID(
  projectID: number
): ReturnType<typeof buildConversationsFromUnisonProjectID> {
  return useBuilder(buildConversationsFromUnisonProjectID, projectID);
}

export function usePracticeConversations(
  practiceID?: number
): ReturnType<typeof buildConversationsFromPracticeID> {
  return useBuilder(buildConversationsFromPracticeID, practiceID);
}

export function useSequence(
  sequenceID: number
): ReturnType<typeof buildSequence> {
  return useBuilder(buildSequence, sequenceID);
}

export function useSequencesFromFileCollectionID(
  fileCollectionID?: number | null
): ReturnType<typeof buildSequencesFromFileCollectionID> {
  return useBuilder(buildSequencesFromFileCollectionID, fileCollectionID);
}

export function useSequenceSteps(
  sequenceID?: number | null
): ReturnType<typeof buildStepsFromSequenceID> {
  return useBuilder(buildStepsFromSequenceID, sequenceID);
}

export function useStep(stepID?: number | null): ReturnType<typeof buildStep> {
  return useBuilder(buildStep, stepID);
}

export function useFilesByStepID(
  stepID: number
): ReturnType<typeof buildFilesByStepID> {
  return useBuilder(buildFilesByStepID, stepID);
}

export function useFileCollectionsByStepID(
  stepID: number
): ReturnType<typeof buildFileCollectionsByStepID> {
  return useBuilder(buildFileCollectionsByStepID, stepID);
}

export function useTreeByStepID(
  stepID: number
): ReturnType<typeof buildTreeByStepID> {
  return useBuilder(buildTreeByStepID, stepID);
}
