import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type {
  DecoratedConversation,
  UndecoratedConversation,
} from '@witmetrics/api-client';
import { setActiveAccount } from './activeAccountSlice';
import { setUnisonProjectConversations } from './unisonProjectConversationsSlice';
import { addToIDArray } from '../utils/stateFormatting';

type ConversationsState = {
  byID: Record<string, UndecoratedConversation>;
  byPracticeID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byPracticeID: {},
} satisfies ConversationsState as ConversationsState;

export const conversationsSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    setConversations: (
      state,
      action: PayloadAction<DecoratedConversation[]>
    ) => {
      return getNextState(state, action.payload);
    },
    addConversation: (state, action: PayloadAction<DecoratedConversation>) => {
      return getNextState(state, [action.payload]);
    },
    updateConversation: (
      state,
      action: PayloadAction<DecoratedConversation>
    ) => {
      return getNextState(state, [action.payload]);
    },
    deleteConversation: (
      state,
      action: PayloadAction<{ conversationID: number }>
    ) => {
      delete state.byID[action.payload.conversationID];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byPracticeID: {} };
      })
      .addCase(setUnisonProjectConversations, (state, action) => {
        return getNextState(
          state,
          action.payload.map((upc) => formatConversation(upc.conversation))
        );
      })
      .addDefaultCase(() => {});
  },
});

function formatConversation({ users, ...conversation }: DecoratedConversation) {
  return conversation;
}

function getNextState(
  state: ConversationsState,
  conversations: Omit<DecoratedConversation, 'users'>[]
) {
  let byID = { ...state.byID };
  let byPracticeID = { ...state.byPracticeID };
  conversations.forEach((c) => {
    const key = `${c.id}`;
    byID[key] = c;
    byPracticeID[c.practiceID] = addToIDArray(byPracticeID[c.practiceID], key);
  });
  return { byID, byPracticeID };
}

export const {
  setConversations,
  addConversation,
  updateConversation,
  deleteConversation,
} = conversationsSlice.actions;

export default conversationsSlice.reducer;
