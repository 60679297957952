export const LOGIN = 'LOGIN';
export const ACCOUNTS = 'ACCOUNTS';
export const SEARCH = 'SEARCH';
export const SHORTCUTS = 'SHORTCUTS';
export const PROJECT = 'PROJECT';
export const NAVIGATION = 'NAVIGATION';

export const DIALOGS = {
  LOGIN,
  ACCOUNTS,
  SEARCH,
  SHORTCUTS,
  PROJECT,
  NAVIGATION,
} as const;
