import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { DecoratedUnisonProject } from '@witmetrics/api-client';
import { setActiveAccount } from './activeAccountSlice';
import { addToIDArray } from '../utils/stateFormatting';

type UnisonProjectsState = {
  byID: Record<string, DecoratedUnisonProject>;
  byPracticeID: Record<string, string[]>;
};

const initialState = {
  byID: {},
  byPracticeID: {},
} satisfies UnisonProjectsState as UnisonProjectsState;

export const unisonProjectsSlice = createSlice({
  name: 'unisonProjects',
  initialState,
  reducers: {
    setUnisonProjects: (
      state,
      action: PayloadAction<DecoratedUnisonProject[]>
    ) => {
      const unisonProjects = action.payload.map(getFlatUnisonProject);
      return getNextState(state, unisonProjects);
    },
    addUnisonProject: (
      state,
      action: PayloadAction<DecoratedUnisonProject>
    ) => {
      return getNextState(state, [action.payload]);
    },
    updateUnisonProject: (
      state,
      action: PayloadAction<DecoratedUnisonProject>
    ) => {
      return getNextState(state, [action.payload]);
    },
    deleteUnisonProject: (
      state,
      action: PayloadAction<{ projectID: number }>
    ) => {
      const key = `${action.payload.projectID}`;
      if (!state.byID[key]) return state;
      const { practiceID } = state.byID[key];
      if (state.byPracticeID[practiceID]) {
        state.byPracticeID[practiceID] = state.byPracticeID[practiceID].filter(
          (id) => {
            return id !== key;
          }
        );
      }
      delete state.byID[key];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setActiveAccount, () => {
        return { byID: {}, byPracticeID: {} };
      })
      .addDefaultCase(() => {});
  },
});

function getFlatUnisonProject(unisonProject: DecoratedUnisonProject) {
  // TODO: This will probably be more decorated as the API gets refactored
  return unisonProject;
}

function getNextState(
  state: UnisonProjectsState,
  unisonProjects: DecoratedUnisonProject[]
) {
  let byID = { ...state.byID };
  let byPracticeID = { ...state.byPracticeID };
  unisonProjects.forEach((up) => {
    const key = `${up.id}`;
    byID[key] = getFlatUnisonProject(up);
    byPracticeID[up.practiceID] = addToIDArray(
      byPracticeID[up.practiceID],
      key
    );
  });
  return { byID, byPracticeID };
}

export const {
  setUnisonProjects,
  addUnisonProject,
  updateUnisonProject,
  deleteUnisonProject,
} = unisonProjectsSlice.actions;

export default unisonProjectsSlice.reducer;
